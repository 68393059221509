import { FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Loading from '../../../core/components/utility/Loading';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import constants from '../../../core/utils/constants';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import NewsForm from '../components/NewsForm';
import { News, NewsInputType } from '../news';

const EditNewsContainer = () => {
  const { id } = useParams();
  const { fetchData, loading } = useFetchApiData();
  const { callApi, loading: dataSending } = useSendApiData();
  const [news, setNews] = useState<News | null>(null);
  const [initialValues, setInitialValues] = useState<NewsInputType>({
    title: '',
    description: '',
    content: '',
    author: '',
    alt: '',
  });

  const fetchNews = () =>
    fetchData(`news/${id}`, {
      onSuccess: (data: News) => {
        setNews(data);
        setInitialValues({
          title: data.title,
          description: data.description,
          content: data.content || '',
          alt: data.alt || '',
          author: data.author,
        });
      },
    });

  useEffect(() => {
    fetchNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: NewsInputType,
    { setFieldError }: FormikHelpers<NewsInputType>
  ) => {
    let success = false;
    await callApi({
      endpoint: `news/${news!.id}?_method=PATCH`,
      data: values,
      method: 'post',
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchNews();
        toastMessage('News updated');
        success = true;
      },
    });

    return success;
  };

  if (loading || !news) return <Loading />;

  let coverImage = '';
  if (news!.media.length) {
    const { id, file_name } = news!.media[0];
    coverImage = `${constants.apiUrl.replace(
      '/api',
      ''
    )}/media/${id}/${file_name}`;
  }

  return (
    <>
      <NewsForm
        initialValues={initialValues}
        coverImage={coverImage}
        onSubmit={handleSubmit}
        submitting={dataSending}
      />
    </>
  );
};

export default EditNewsContainer;
