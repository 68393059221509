import Layout from '../../../core/components/layout/Layout';
import AddLawContainer from '../containers/AddLawContainer';

const AddLawPage = () => {
  return (
    <>
      <Layout>
        <AddLawContainer />
      </Layout>
    </>
  );
};

export default AddLawPage;
