import { useState, useEffect } from 'react';
import useTablePagination from '../../../core/components/pagination/useTablePagination';
import DeleteAction from '../../../core/components/utility/DeleteAction';
import Loading from '../../../core/components/utility/Loading';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import PublicationsTable from '../components/PublicationTable';
import { Publication } from '../publication';

const PublicationsContainer = () => {
  const {
    data: { page, rowsPerPage, total },
    handlers: { handleChangePage, handleChangeRowsPerPage, setTotal },
  } = useTablePagination();
  const [publications, setPublications] = useState<Publication[]>([]);
  const { fetchData, loading } = useFetchApiData();
  const { callApi } = useSendApiData();

  const fetchPublications = () =>
    fetchData(`publications?page=${page + 1}&limit=${rowsPerPage}`, {
      onSuccess: (response) => {
        const { data, total } = response;
        setTotal(total);
        setPublications(data);
      },
    });
  useEffect(() => {
    fetchPublications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleDeletion = async (curPublication: Publication) => {
    await callApi({
      endpoint: `publications/${curPublication.id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        toastMessage('Publication Removed');
        // TODO: Fix Unmount component issue
        setTimeout(() => fetchPublications(), 100);
      },
      onError: () => {
        toastError('Something went wrong while deleting. Try again');
      },
    });
  };
  // console.log('load', loading);

  if (loading) return <Loading />;

  return (
    <>
      <PublicationsTable
        data={publications}
        pagination={{
          page,
          rowsPerPage,
          total,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
        renderDelete={(delPublication) => (
          <DeleteAction
            message="Are you sure you want to delete publication?"
            onDelete={() => handleDeletion(delPublication)}
          />
        )}
      />
    </>
  );
};

export default PublicationsContainer;
