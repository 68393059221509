import Layout from '../../../core/components/layout/Layout';
import AddNewsContainer from '../containers/AddNewsContainer';

const AddNewsPage = () => {
  return (
    <>
      <Layout>
        <AddNewsContainer />
      </Layout>
    </>
  );
};

export default AddNewsPage;
