import { useState, useEffect } from 'react';
import useTablePagination from '../../../core/components/pagination/useTablePagination';
import DeleteAction from '../../../core/components/utility/DeleteAction';
import Loading from '../../../core/components/utility/Loading';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import NewssTable from '../components/NewsTable';
import { News } from '../news';

const NewssContainer = () => {
  const {
    data: { page, rowsPerPage, total },
    handlers: { handleChangePage, handleChangeRowsPerPage, setTotal },
  } = useTablePagination();
  const [news, setNewss] = useState<News[]>([]);
  const { fetchData, loading } = useFetchApiData();
  const { callApi } = useSendApiData();

  const fetchNewss = () =>
    fetchData(`news?page=${page + 1}&limit=${rowsPerPage}`, {
      onSuccess: (response) => {
        const { data, total } = response;
        setTotal(total);
        setNewss(data);
      },
    });
  useEffect(() => {
    fetchNewss();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleDeletion = async (curNews: News) => {
    await callApi({
      endpoint: `news/${curNews.id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        toastMessage('News Removed');
        // TODO: Fix Unmount component issue
        setTimeout(() => fetchNewss(), 100);
      },
      onError: () => {
        toastError('Something went wrong while deleting. Try again');
      },
    });
  };
  // console.log('load', loading);

  if (loading) return <Loading />;

  return (
    <>
      <NewssTable
        data={news}
        pagination={{
          page,
          rowsPerPage,
          total,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
        renderDelete={(delNews) => (
          <DeleteAction
            message="Are you sure you want to delete news?"
            onDelete={() => handleDeletion(delNews)}
          />
        )}
      />
    </>
  );
};

export default NewssContainer;
