import Layout from '../../../core/components/layout/Layout';
import EditPublicationContainer from '../containers/EditPublicationContainer';

const EditPublicationPage = () => {
  return (
    <>
      <Layout>
        <EditPublicationContainer />
      </Layout>
    </>
  );
};

export default EditPublicationPage;
