// import { useContext } from 'react';
import Layout from '../../../core/components/layout/Layout';
// import Loading from '../../../core/components/utility/Loading';
// import AuthContext from '../../auth/service/authContext';

const Home = () => {
  // const { user } = useContext(AuthContext);

  return (
    <>
      {/* <Loading /> */}
      <Layout>
        <></>
      </Layout>
    </>
  );
};

export default Home;
