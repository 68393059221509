import { useState, useEffect } from 'react';
import useTablePagination from '../../../core/components/pagination/useTablePagination';
import DeleteAction from '../../../core/components/utility/DeleteAction';
import Loading from '../../../core/components/utility/Loading';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import LawsTable from '../components/LawsTable';
import { Law } from '../law';

const LawsContainer = () => {
  const {
    data: { page, rowsPerPage, total },
    handlers: { handleChangePage, handleChangeRowsPerPage, setTotal },
  } = useTablePagination();
  const [laws, setLaws] = useState<Law[]>([]);
  const { fetchData, loading } = useFetchApiData();
  const { callApi } = useSendApiData();

  const fetchLaws = () =>
    fetchData(`laws?page=${page + 1}&limit=${rowsPerPage}`, {
      onSuccess: (response) => {
        const { data, total } = response;
        setTotal(total);
        setLaws(data);
      },
    });
  useEffect(() => {
    fetchLaws();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleDeletion = async (curLaw: Law) => {
    await callApi({
      endpoint: `laws/${curLaw.id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        toastMessage('Law Removed');
        // TODO: Fix Unmount component issue
        setTimeout(() => fetchLaws(), 100);
      },
      onError: () => {
        toastError('Something went wrong while deleting. Try again');
      },
    });
  };
  // console.log('load', loading);

  if (loading) return <Loading />;

  return (
    <>
      <LawsTable
        data={laws}
        pagination={{
          page,
          rowsPerPage,
          total,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
        renderDelete={(delLaw) => (
          <DeleteAction
            message="Are you sure you want to delete law?"
            onDelete={() => handleDeletion(delLaw)}
          />
        )}
      />
    </>
  );
};

export default LawsContainer;
