import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import EditLawForm from '../components/LawForm';
import { LawInputType } from '../law';

const emptyValue: LawInputType = {
  name: '',
};

const AddLawContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  const [initialValues, setInitialValues] = useState<LawInputType>(emptyValue);
  const navigate = useNavigate();

  const handleSubmit = async (
    values: LawInputType,
    { setFieldError }: FormikHelpers<LawInputType>
  ) => {
    let success = false;
    await callApi({
      endpoint: `laws`,
      data: values,
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Law Created');
        setInitialValues(emptyValue);
        success = true;
        navigate('/laws', { replace: true });
      },
    });

    return success;
  };

  return (
    <>
      <Grid container sx={{ mb: 1, px: 1 }}>
        <Typography variant="h5">Add Law</Typography>
      </Grid>
      <EditLawForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        submitting={submitting}
        creating={true}
      />
    </>
  );
};

export default AddLawContainer;
