import { Box, Button, Grid, Paper, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { Renderer } from '../../../core/utils/types';
import { LawInputType } from '../law';
import Placeholder from '../../../core/utils/ui/placeholder';

type EditLawProps = {
  initialValues: LawInputType;
  onSubmit: (
    value: LawInputType,
    helpers: FormikHelpers<LawInputType>
  ) => Promise<boolean>;
  submitting: boolean;
  creating?: boolean;
  renderAction?: Renderer;
};

const EditLawForm = ({
  initialValues,
  onSubmit,
  submitting,
  creating = false,
  renderAction = Placeholder,
}: EditLawProps) => {
  const handleSubmit = async (
    value: LawInputType,
    helpers: FormikHelpers<LawInputType>
  ) => {
    const success = await onSubmit(value, helpers);
    if (creating && success) formik.resetForm();
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="name"
                      variant="standard"
                      label="Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item sx={{ flexGrow: 1 }}></Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
                {renderAction()}
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditLawForm;
