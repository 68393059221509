import Layout from '../../../core/components/layout/Layout';
import EditGeneralContainer from '../containers/EditGeneralContainer';

const EditGeneralPage = () => {
  return (
    <>
      <Layout>
        <EditGeneralContainer />
      </Layout>
    </>
  );
};

export default EditGeneralPage;
