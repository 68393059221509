import { Box, Button, Grid, Paper, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { Renderer } from '../../../core/utils/types';
import { NewsInputType } from '../news';
import Placeholder from '../../../core/utils/ui/placeholder';
import ReactQuill from 'react-quill';
import { useState } from 'react';

type EditNewsProps = {
  initialValues: NewsInputType;
  onSubmit: (
    value: NewsInputType,
    helpers: FormikHelpers<NewsInputType>
  ) => Promise<boolean>;
  submitting: boolean;
  creating?: boolean;
  renderAction?: Renderer;
  coverImage?: string;
};

const EditNewsForm = ({
  initialValues,
  onSubmit,
  submitting,
  creating = false,
  renderAction = Placeholder,
  coverImage,
}: EditNewsProps) => {
  const [content, setContent] = useState({ text: initialValues.content || '' });
  const [image, setImage] = useState<any>(null);

  const handleContentChange = (val: string) => {
    setContent({ text: val });
  };

  const handleSubmit = async (
    value: NewsInputType,
    helpers: FormikHelpers<NewsInputType>
  ) => {
    const formData = new FormData();
    const data = { ...value, content: content.text, image: image || undefined };
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));

    const success = await onSubmit(
      formData as unknown as NewsInputType,
      helpers
    );

    if (creating && success) {
      formik.resetForm();
      setContent({ text: '' });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link', 'image'],
    ],
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      variant="standard"
                      label="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                      required
                    />
                  </Grid>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="author"
                      variant="standard"
                      label="Author"
                      value={formik.values.author}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.author)}
                      helperText={formik.errors.author}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 1 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      variant="standard"
                      label="Description"
                      multiline
                      rows={5}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      required
                    />
                  </Grid>
                  {/* <Grid item sx={{ flexGrow: 1 }}></Grid> */}
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 1 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <ReactQuill
                      modules={modules}
                      value={content.text}
                      onChange={handleContentChange}
                      placeholder={'Write something'}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1, mt: 1 }}>
                    <Grid item xs={12}>
                      Image
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        required={creating}
                        onChange={(e) => {
                          setImage(e.target.files![0]);
                        }}
                        name="image"
                      />
                    </Box>
                  </Grid>
                  <Grid item sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="alt"
                      variant="standard"
                      label="Image Description(optional)"
                      multiline
                      rows={5}
                      value={formik.values.alt}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.alt)}
                      helperText={formik.errors.alt}
                    />
                  </Grid>
                  {coverImage && (
                    <Grid item xs={12}>
                      <img src={coverImage} width="256px" alt="cover" />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
                {renderAction()}
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditNewsForm;
