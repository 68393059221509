import Layout from '../../../core/components/layout/Layout';
import AddGeneralContainer from '../containers/AddGeneralContainer';

const AddGeneralPage = () => {
  return (
    <>
      <Layout>
        <AddGeneralContainer />
      </Layout>
    </>
  );
};

export default AddGeneralPage;
