import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useState } from 'react';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import EditNewsForm from '../components/NewsForm';
import { NewsInputType } from '../news';

const emptyValue: NewsInputType = {
  title: '',
  description: '',
  content: '',
  author: '',
  alt: '',
};

const AddNewsContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  const [initialValues, setInitialValues] = useState<NewsInputType>(emptyValue);

  const handleSubmit = async (
    values: NewsInputType,
    { setFieldError }: FormikHelpers<NewsInputType>
  ) => {
    let success = false;
    await callApi({
      endpoint: `news`,
      data: values,
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('News Created');
        setInitialValues(emptyValue);
        success = true;
      },
    });

    return success;
  };

  return (
    <>
      <Grid container sx={{ mb: 1, px: 1 }}>
        <Typography variant="h5">Add News</Typography>
      </Grid>
      <EditNewsForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        submitting={submitting}
        creating={true}
      />
    </>
  );
};

export default AddNewsContainer;
