import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GeneralPage from '../../features/general/pages/GeneralPage';
import Home from '../../features/home/components/Home';
import EditGeneralPage from '../../features/general/pages/EditGeneralPage';
import AddGeneralPage from '../../features/general/pages/AddGeneralPage';
import LawPage from '../../features/law/pages/LawPage';
import AddLawPage from '../../features/law/pages/AddLawPage';
import EditLawPage from '../../features/law/pages/EditLawPage';
import PublicationPage from '../../features/publication/pages/PublicationPage';
import AddPublicationPage from '../../features/publication/pages/AddPublicationPage';
import EditPublicationPage from '../../features/publication/pages/EditPublicationPage';
import NewsPage from '../../features/news/pages/NewsPage';
import AddNewsPage from '../../features/news/pages/AddNewsPage';
import EditNewsPage from '../../features/news/pages/EditNewsPage';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/generals"
          element={
            <PrivateRoute>
              <GeneralPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/generals/create"
          element={
            <PrivateRoute>
              <AddGeneralPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/generals/edit/:id"
          element={
            <PrivateRoute>
              <EditGeneralPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/laws"
          element={
            <PrivateRoute>
              <LawPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/laws/create"
          element={
            <PrivateRoute>
              <AddLawPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/laws/edit/:id"
          element={
            <PrivateRoute>
              <EditLawPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications"
          element={
            <PrivateRoute>
              <PublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/create"
          element={
            <PrivateRoute>
              <AddPublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/edit/:id"
          element={
            <PrivateRoute>
              <EditPublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news"
          element={
            <PrivateRoute>
              <NewsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/create"
          element={
            <PrivateRoute>
              <AddNewsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/edit/:id"
          element={
            <PrivateRoute>
              <EditNewsPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
