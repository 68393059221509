import { Grid, Typography } from '@mui/material';
import LinkButton from '../../../core/components/button/LinkButton';
import Layout from '../../../core/components/layout/Layout';
import LawsContainer from '../containers/LawsContainer';

const LawPage = () => {
  return (
    <Layout>
      <>
        <Grid container justifyContent="space-between" sx={{ my: 1 }}>
          <Grid item>
            <Typography variant="h5">Laws</Typography>
          </Grid>
          <Grid item>
            <LinkButton to="/laws/create" label="+Add Law" />
          </Grid>
        </Grid>
        <LawsContainer />
      </>
    </Layout>
  );
};

export default LawPage;
