import { Grid } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import FileListItem from '../../../core/components/utility/FileListItem';
import Loading from '../../../core/components/utility/Loading';
import FileActions from '../../../core/containers/FileUploader';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import useFileUpload from '../../../core/hooks/useFileUpload';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { Media } from '../../../core/utils/types';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import LawForm from '../components/LawForm';
import { Law, LawInputType } from '../law';

const EditLawContainer = () => {
  const { id } = useParams();
  const { fetchData, loading } = useFetchApiData();
  const { callApi, loading: dataSending } = useSendApiData();
  const { uploadFile, uploading, progress } = useFileUpload();
  const { callApi: callDeleteApi } = useSendApiData();
  const [law, setLaw] = useState<Law | null>(null);
  const [initialValues, setInitialValues] = useState<LawInputType>({
    name: '',
  });

  const fetchLaw = () =>
    fetchData(`laws/${id}`, {
      onSuccess: (data: Law) => {
        setLaw(data);
        setInitialValues({
          name: data.name,
        });
      },
    });

  useEffect(() => {
    fetchLaw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: LawInputType,
    { setFieldError }: FormikHelpers<LawInputType>
  ) => {
    let success = false;
    callApi({
      endpoint: `laws/${law!.id}`,
      data: values,
      method: 'patch',
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchLaw();
        toastMessage('Law updated');
        success = true;
      },
    });

    return success;
  };

  const handleUpload = async (file: File): Promise<boolean> => {
    const formData = new FormData();
    formData.append('file', file!);

    let uploaded = false;

    await uploadFile(`laws/${law!.id}/upload-file`, formData, {
      onSuccess: () => {
        toastMessage('File Uploaded');
        uploaded = true;
        setTimeout(() => fetchLaw(), 100);
      },
      onError: (err) => {
        toastError(err);
      },
    });

    return uploaded;
  };

  const handleFileDelete = async (media: Media) => {
    await callDeleteApi({
      endpoint: `laws/${law!.id}/delete-file/${media.id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        toastMessage('File Removed');
        // TODO: Fix Unmount component issue
        setTimeout(() => fetchLaw(), 100);
      },
      onError: () => {
        toastError('Something went wrong while deleting. Try again');
      },
    });
  };

  const ActionComponent = () => {
    return (
      <>
        <FileActions
          onSubmit={handleUpload}
          uploading={uploading}
          progress={progress}
        />

        <Grid container sx={{ mt: 1 }}>
          {law &&
            law.media.map((m) => (
              <FileListItem
                media={m}
                key={m.id}
                onDelete={() => handleFileDelete(m)}
              />
            ))}
        </Grid>
      </>
    );
  };

  if (loading || !law) return <Loading />;

  return (
    <>
      <LawForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        submitting={dataSending}
        renderAction={ActionComponent}
      />
    </>
  );
};

export default EditLawContainer;
