import Layout from '../../../core/components/layout/Layout';
import EditLawContainer from '../containers/EditLawContainer';

const EditLawPage = () => {
  return (
    <>
      <Layout>
        <EditLawContainer />
      </Layout>
    </>
  );
};

export default EditLawPage;
