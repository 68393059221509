import { Grid, Typography } from '@mui/material';
import LinkButton from '../../../core/components/button/LinkButton';
import Layout from '../../../core/components/layout/Layout';
import PublicationsContainer from '../containers/PublicationsContainer';

const PublicationPage = () => {
  return (
    <Layout>
      <>
        <Grid container justifyContent="space-between" sx={{ my: 1 }}>
          <Grid item>
            <Typography variant="h5">Publications</Typography>
          </Grid>
          <Grid item>
            <LinkButton to="/publications/create" label="+Add Publication" />
          </Grid>
        </Grid>
        <PublicationsContainer />
      </>
    </Layout>
  );
};

export default PublicationPage;
