import Layout from '../../../core/components/layout/Layout';
import AddPublicationContainer from '../containers/AddPublicationContainer';

const AddPublicationPage = () => {
  return (
    <>
      <Layout>
        <AddPublicationContainer />
      </Layout>
    </>
  );
};

export default AddPublicationPage;
