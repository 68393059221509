import { Grid, Typography } from '@mui/material';
import LinkButton from '../../../core/components/button/LinkButton';
import Layout from '../../../core/components/layout/Layout';
import NewsContainer from '../containers/NewsContainer';

const NewsPage = () => {
  return (
    <Layout>
      <>
        <Grid container justifyContent="space-between" sx={{ my: 1 }}>
          <Grid item>
            <Typography variant="h5">News</Typography>
          </Grid>
          <Grid item>
            <LinkButton to="/news/create" label="+Add News" />
          </Grid>
        </Grid>
        <NewsContainer />
      </>
    </Layout>
  );
};

export default NewsPage;
