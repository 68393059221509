import Layout from '../../../core/components/layout/Layout';
import EditNewsContainer from '../containers/EditNewsContainer';

const EditNewsPage = () => {
  return (
    <>
      <Layout>
        <EditNewsContainer />
      </Layout>
    </>
  );
};

export default EditNewsPage;
